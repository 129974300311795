import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';

export const Dashboard = () => {
  const { user } = useAuth();
  return (
    <ContentLayout title="Dashboard">
      <h1 className="text-xl mt-2 dark:text-gray-400">
        Welcome <b>{`${user?.name}`}</b>
      </h1>
      <p className="font-medium dark:text-gray-400">In this application you can:</p>
      {user?.role === ROLES.USER && (
        <ul className="my-4 list-inside list-disc">
          <li>Create comments in posts</li>
          <li>Delete own comments</li>
        </ul>
      )}
      <ul className="my-4 list-inside list-disc dark:text-gray-400">
        <li>Create posts</li>
        <li>Edit posts</li>
        <li>Delete posts</li>
        <li>Comment on posts</li>
        <li>Delete all comments</li>
      </ul>
    </ContentLayout>
  );
};
